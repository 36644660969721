import {ISubscription} from '../types';
import type { RootState } from '../reducers';
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface SubscriptionState {
	loading?: boolean;
	loaded: boolean;
	subscriptions?: ISubscription[];
	prices: {
		[pkg: string]: number;
	};
	subscriptionUrl: string;
}

export const initialState: SubscriptionState = {
	loaded: false,
	subscriptions: [],
	prices: {
		month: 3900,
		year: 24900
	},
	subscriptionUrl: '/users/purchases/woocommerce_products/new'
};

export const subscriptionSlice = createSlice({
	name: 'subscription',
	initialState,
	reducers: {
		loading: state => { state.loading = true; state.loaded = false; },
		loaded: (state, action: PayloadAction<{ subscriptions: ISubscription[] }>) => {
			if (action.payload.subscriptions) {
				state.subscriptions = action.payload.subscriptions;
			}

			state.loading = false;
			state.loaded = true;
		},
		configLoaded: (state, action: PayloadAction<{ config: { subscriptionUrl: string, prices: { [pkg:string]: number; }} }>) => {
			state.subscriptionUrl = action.payload.config.subscriptionUrl;
			state.prices = action.payload.config.prices;
		},
		resetSubscriptionSites: (state, action: PayloadAction<ISubscription>) => {},
		refreshSubscription: (state, action: PayloadAction<ISubscription>) => {
			const subscription = action.payload;

			let updatedSubscriptions = state.subscriptions.map(sub => {
				if (subscription.id == sub.id) {
					return subscription;
				}

				return sub;
			});

			state.subscriptions = updatedSubscriptions;
		}
	}
});

export const { loaded, loading, configLoaded, refreshSubscription, resetSubscriptionSites } = subscriptionSlice.actions;

export const allSubscriptions = (state: RootState) => state.subscription.loaded && state.subscription.subscriptions;
export const selectSubscriptionById = (state: RootState, id: number) => {
	return state.subscription.subscriptions.find(s => s.id == id);
}

export default subscriptionSlice.reducer;