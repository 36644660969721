import {IUser} from '../types';
import type { RootState } from '../reducers';
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface AuthState {
	loading?: boolean;
	loaded: boolean;
	loggedIn: boolean;
	user?: IUser | undefined;
	error?: string;
}

export const initialState: AuthState = {
	loggedIn: false,
	loaded: false,
};

export const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		loading: state => { state.loading = true; state.loaded = false; },
		authenticated: (state, action: PayloadAction<{ user: IUser | undefined, error?: string}>) => {
			state.user = undefined;
			state.error = undefined;

			if (action.payload.user) {
				state.user = action.payload.user;
				state.loggedIn = true;
			} else {
				state.loggedIn = false;
				state.error = action.payload.error;
			}

			state.loading = false;
			state.loaded = true;
		}
	}
});

export const { authenticated, loading } = authSlice.actions;

export const getLoggedIn = (state: RootState) => state.auth.loggedIn;
export const getUser = (state: RootState) => state.auth.user;

export default authSlice.reducer;