import * as React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Woopower from "../products/Woopower";
import Subscription from "../woocommerce/Subscription";
import SubscriptionList from "../woocommerce/SubscriptionList";

const redirectActive = window['redirectTo'] !== undefined;
const redirectDest = window['redirectTo'];

window['redirectTo'] = undefined;

if (redirectActive) {
	console.log(`Redirecting to ${redirectDest}`);
}

export default (
	<Switch>
		<Route path="/subscriptions/:id" exact component={Subscription} />
		<Route path="/subscriptions" exact component={SubscriptionList} />
		<Route path="/products/woopower" exact component={Woopower} />
		<Route path="*">
			<Redirect push to={redirectDest || "/products/woopower"} />
		</Route>
	</Switch>
);
