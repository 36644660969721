import { getTheme, mergeStyleSets, Text, Icon } from "@fluentui/react";
import * as React from "react";
import { PropsWithChildren } from "react";

const theme = getTheme();

const style = mergeStyleSets({
	question: [
		theme.fonts.xLarge,
		{
			fontWeight: 'bold',
			paddingTop: '20px'

		}
	],
	answer: [
		theme.fonts.large,
		{

		}
	]
});

export const Question: React.FC<PropsWithChildren<{question: string}>> = (props) => {
	return <>
		<Text className={style.question} block>
			<Icon iconName="FeedbackRequestSolid" style={ {paddingRight: '10px', color: theme.palette.themePrimary }} />
			{props.question}
		</Text>
		<Text className={style.answer} block>
			{props.children}
		</Text>
	</>;
}