import { CommandBar, ThemeProvider, ICommandBarProps, mergeStyleSets, PartialTheme } from "@fluentui/react";
import * as React from "react";

const bgcolor = '#eee';

const styles = mergeStyleSets({
	AppCommandBar: {
		backgroundColor: bgcolor,
		marginLeft: '-32px',
	}
});

const appCommandBarTheme: PartialTheme = {
	palette: {
		white: bgcolor
	}
}

const AppCommandBar: React.FC<ICommandBarProps> = (props) => {
	const commandBarProps: ICommandBarProps = {
		className: `${styles.AppCommandBar} ${props.className ||''}`,
		...props
	};

	return <ThemeProvider theme={appCommandBarTheme}>
			<CommandBar {...commandBarProps} />
		</ThemeProvider>;
}

export default AppCommandBar;