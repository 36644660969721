import { getTheme, mergeStyleSets, MessageBar } from '@fluentui/react';
import * as React from 'react';
import { useState } from 'react';

const theme = getTheme();
const styles = mergeStyleSets({
	flash: {
		marginBottom: '2em',
	}

})


export const Flash: React.FC = () => {
	const [flash, setFlash] = useState(window['flash']);
	return (
		( flash !== undefined ? 
			<MessageBar onDismiss={() => setFlash(undefined)} className={styles.flash}>
				{window['flash']}
			</MessageBar>
		:
		 <></>
		)
	)
}

export default Flash;