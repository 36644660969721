import { getTheme, INavLinkGroup, mergeStyleSets, Nav, INavProps, css, INavStyles, Icon, INavLink } from '@fluentui/react';
import * as React from 'react';
import { navigateTo } from '../src/hooks';
import { logo } from './woocommerce/Subscription';
import { doLogin } from './LoginButton';

declare var require: any;
export const wordpresslogo = require('../src/assets/wordpress.svg');

const theme = getTheme();

const styles = mergeStyleSets({
	AppSidebar: [
		"AppSidebar",
		{
		backgroundColor: theme.palette.neutralLight,
		padding: '10px 0',
		borderRight: '1px solid rgb(210, 210, 210)',
		color: theme.palette.black
	}],
	collapseIcon: [
		"collapseIcon",
		{
		cursor: 'pointer',
		paddingLeft: '10px',
		textAlign: 'center'
		}]
});

const navHiddenStyles: Partial<INavStyles> = {
	linkText: {
		display: 'none'
	}
}

export const AppSidebar: React.FC<{loggedIn: boolean}> = (props) => {
	const [sidebarOpen, setSidebarOpen] = React.useState(window.innerWidth >= 800);

	const subscriptionsNav: INavLink = {
				key: 'subs',
				name: 'Subscriptions',
				url: '/subscriptions',
				icon: 'FavoriteList'
			};

	const loginNav: INavLink = {
		key: 'login',
		name: 'Login with WordPress.com',
		url: '/users/auth/wordpress',
		iconProps: {
			imageProps: {
				src: wordpresslogo,
				width: 16
			}
		},
		onClick: doLogin
	};

	const userNavGroups:INavLinkGroup[] = [{
		links: [
			{
				key: 'products',
				name: 'Products',
				icon: 'ProductList',
				isExpanded: true,
				url: '#',
				links: [{
					key: 'woopower',
					name: 'Power Automate for WooCommerce',
					url: '/products/woopower',
					iconProps: {
						imageProps: {
							src: logo,
							width: 16
						}
					}
				}]
			}]
	}];

	const navigateToItem: INavProps["onLinkClick"] = (ev, item) => {
		ev.preventDefault();
		navigateTo(item.url);
	}

	return (
		<div className={styles.AppSidebar}>
			<Icon iconName='GlobalNavButton' className={styles.collapseIcon} onClick={() => setSidebarOpen(!sidebarOpen) } />
			<Nav
				className={ `NavCollapsable ${sidebarOpen ? 'open' : ''}` }
				groups={userNavGroups}
				onLinkClick={navigateToItem}
				/>
		</div>
	)
}

export default AppSidebar;