import { mergeStyleSets, Text, getTheme, IconButton, TooltipHost, PrimaryButton, Shimmer, Link, ImageIcon, IBreadcrumbItem, Breadcrumb, ICommandBarItemProps } from '@fluentui/react';
import * as React from "react"
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom'
import { cents_to_dollars } from '../../../src/helpers';
import { navigateTo, useAppDispatch, useAppSelector } from '../../../src/hooks';
import { RootState } from '../../../src/reducers';
import { loading, resetSubscriptionSites, selectSubscriptionById } from '../../../src/reducers/subscription'
import AppCommandBar from '../../AppCommandBar';

import './styles.scss';

declare var require: any;
export const logo = require('../woo-icon.png');

const theme = getTheme();

const style = mergeStyleSets({
	title: [
		"title",
		theme.fonts.xxLarge,
		{
			paddingBottom: '10px'
		}
	],
	cta: {
	},
	textBlock: [
		{
			padding: '10px 0'
		}
	],
	details: {
		padding: '20px 0'
	},
	dl: [
		'subscriptionDetailList',
		theme.fonts.large,
		{
			
		}
	],
	colored: {
		color: '#944'
	},
	container: {
		margin: '10px',
		padding: '20px',
	},
	logo: {
		paddingRight: '10px',
		marginBottom: '-15px'
	}
});

export const Subscription: React.FunctionComponent = () => {
	const dispatch     = useAppDispatch();
	const isLoaded     = useAppSelector((state: RootState) => state.subscription.loaded);
	const isLoading    = useAppSelector((state: RootState) => state.subscription.loading);
	const { id }       = useParams();
	const subscription = useAppSelector((state: RootState) => selectSubscriptionById(state, id));

	// Fetches subscriptions when we render the page
	useEffect(() => {
		if (!isLoaded) {
			dispatch(loading());
		}
	}, []);

	const [copyHint, setCopyHint] = useState("Copy License Key to Clipboard");

	const copyLicenseToClipboard = () => {
		if (navigator.clipboard) {
			navigator.clipboard.writeText(license.key);
			setCopyHint("Copied!");
		}
	}

	const license = subscription && subscription.licenses[0];

	const subscriptionStatus = () => {
		const status = subscription.status;

		if (status === 'pending') {
			return <Link href={subscription.confirm_url}>{status}</Link>
		}

		return status;
	}

	const breadcrumbs: IBreadcrumbItem[] = subscription && [
		{
			text: 'Subscriptions',
			key: 'subscriptions',
			onClick: () => navigateTo('/subscriptions')
		},
		{
			text: subscription.name,
			key: subscription.name
		}
	];

	const headerItems: ICommandBarItemProps[] = [
		{
			key: 'reset',
			text: 'Clear All Active Sites',
			iconProps: { iconName: 'RecycleBin' },
			onClick: () => {
				if (confirm('This will reset all active sites!\n\n To use the product you must activate again.\n Continue?')) {
					dispatch(resetSubscriptionSites(subscription));
				}
			}
		}
	];

	return <>
		<AppCommandBar items={headerItems} />
		{ subscription && 
			<Breadcrumb
				items={breadcrumbs} />
		}
		<div className={style.container}>
			{ isLoading || !isLoaded ?
				<Shimmer />
			:
				subscription ? 
					<div className="subscriptionItem">
						<Text className={style.title} block>
							<ImageIcon imageProps={{ src: logo, width: "50px" }} className={style.logo} />
							{subscription.name}
						</Text>

						<div className={style.cta}>
							<PrimaryButton iconProps={{ iconName: 'Download' }} href="/woopower-1.0.16.zip">
								Download WordPress Plugin (v1.0.16)
							</PrimaryButton>
						</div>

						<Text variant="mediumPlus" block>
							To install, visit the Plugins ➡ Add New area inside your WordPress administration and then upload this plugin there.
						</Text>

						<Text variant="small" block className={style.textBlock}>
							Your subscription is being managed by WooCommerce.com.
							You can <Link href="https://woocommerce.com/my-account/my-subscriptions/">
								manage your subscription here.
							</Link>
						</Text>

						<dl className={style.dl}>
							<dt>Subscription Status</dt>
							<dd>{subscriptionStatus()}</dd>

							<dt>Subscription Date</dt>
							<dd>{subscription.created_at}</dd>

							<dt>Billing Amount</dt>
							<dd>{cents_to_dollars(subscription.price_in_cents)} per {subscription.billing_period}</dd>

							{ license && 
								<>
									<dt>License key</dt>
									<dd>
										{ license.key }
										{ navigator.clipboard && 
											<TooltipHost
												id="copy-license"
												calloutProps={{ gapSpace: 0 }}
												styles={{root: { display: 'inline-block'}}}
												content={copyHint}
											>
												<IconButton iconProps={{ iconName: 'Copy' }} onClick={copyLicenseToClipboard} title="Copy to clipboard"/>
											</TooltipHost>
										}
									</dd>

									<dt>Active Sites</dt>
									<dd>
										{ license.domains.length > 0 ?
											<>
												<ul className="domains">
												{ license.domains.map(domain => {
													return <li key={domain.domain_name}>&#128994; {domain.domain_name}</li>
													})}
												</ul>
											</>
										:
											<em>Install WordPress plugin to activate</em>
										}
									</dd>
								</>
							}
						</dl>
					</div>
				:
				<Text variant="large"> Subscription not found </Text>
			}
		</div>
	</>
}

export default Subscription;