import * as React from "react";
import { DetailsListLayoutMode, getTheme, IColumn, ICommandBarItemProps, Icon, ImageIcon, mergeStyleSets, PrimaryButton, SelectionMode, ShimmeredDetailsList, Text } from '@fluentui/react'
import { RootState } from "../../../src/reducers";
import { useEffect } from "react";
import { allSubscriptions, loading } from "../../../src/reducers/subscription";
import { navigateTo, useAppDispatch, useAppSelector } from "../../../src/hooks";
import { ISubscription } from "../../../src/types";
import { logo } from "../Subscription";
import Flash from "../../Flash";
import AppCommandBar from "../../AppCommandBar";

import './styles.scss';

const theme = getTheme();

const styles = mergeStyleSets({
	header: [
		{
			marginTop: '10px', 
			marginBottom: '16px'

		},
		theme.fonts.xxLarge
	],
	newButton: [{
		marginTop: '1em',
		marginLeft: '1em'
	}],
	container: {
	},
	iconCell: {
		textAlign: 'center',
		selectors: {
      '&:before': {
        content: '.',
        display: 'inline-block',
        verticalAlign: 'middle',
        height: '100%',
        width: '0px',
        visibility: 'hidden',
      },
    },
	},
	iconImg: {
		verticalAlign: 'middle',
		maxWidth: '16px',
		maxHeight: '16px'
	},
	nameColumn: {
		fontWeight: 'bold'
	}
});

const SubscriptionList: React.FC = () => {
	const dispatch           = useAppDispatch();
	const items              = useAppSelector(allSubscriptions);
	const isLoaded           = useAppSelector((state: RootState) => state.subscription.loaded);
	const isLoading          = useAppSelector((state: RootState) => state.subscription.loading);
	const hasNoSubscriptions = useAppSelector((state) => state.subscription.loaded && state.subscription.subscriptions.length == 0)

	// Fetches subscriptions when we render the page
	useEffect(() => {
		if (!isLoaded) {
			dispatch(loading());
		}
	}, []);

	const imgSrc_for: (sku: string) => string = (sku) => {
		switch(sku) {
			case "woopower":
				return logo;
			default:
				return "";
		}
	}

	const columns: IColumn[] = [
		{
			isIconOnly: true,
			name: 'Subscription Type',
			key: 'icon',
			className: styles.iconCell,
			iconClassName: styles.iconImg,
			minWidth: 16,
			maxWidth: 16,
			onRender: ((item: ISubscription) => {
				return <ImageIcon imageProps={{ src: imgSrc_for(item.product_sku), width: "16px" }} />
			}),
		},
		{
			name: 'Name',
			key: 'name',
			className: styles.nameColumn,
			fieldName: 'name',
			minWidth: 250,
			isResizable: true,
			isPadded: true,
			onRender: ((sub: ISubscription) => {
				return <>
					{sub.name} &nbsp;
					{sub.status == 'pending' &&
						<Icon iconName='NavigateExternalInline' />
					}
				</>
			})
		},
		{
			key: 'domains',
			name: 'Active Sites',
			minWidth: 250,
			onRender: ((subscription: ISubscription) => {
				const license = subscription.licenses[0];

				if (license) {
					if (license.can_have_multiple_installs) {
						return <ul>
							{license.domains.map(domain => <li>{domain.domain_name}</li>)}
						</ul>
					} else if (license.siteurl) {
						return <>&#128994; {license.siteurl}</>;
					}
				}

				return <em>Unactivated</em>;
			})
		},
		{
			key: 'status',
			name: 'Status',
			fieldName: 'status',
			minWidth: 48 
		},
		{
			key: 'created_at',
			name: 'Created At',
			fieldName: 'created_at',
			minWidth: 150
		}
	];

	const itemClick = (subscription: ISubscription) => {
		const status = subscription.status;

		if (status === 'pending') {
			window.location.href = subscription.confirm_url;
		} else {
			navigateTo(`/subscriptions/${subscription.id}`);
		}
	}

	const headerItems: ICommandBarItemProps[] = [
		{
			key: 'new',
			text: 'New Subscription',
			iconProps: { iconName: 'Add' },
			onClick: () => navigateTo('/products/woopower')
		}
	];

	return (
		<>
			<AppCommandBar items={headerItems} />
			<div className={styles.container}>
				<Text block className={styles.header}>Subscriptions</Text>
				<Text variant="large" block>
					All of your subscriptions will appear here. If you have multiple subscriptions to a product, each one will appear as a separate item.
				</Text>
				<Flash />
				{ hasNoSubscriptions ?
					<Text variant="medium" block>You have no subscriptions.</Text>
					:
					<ShimmeredDetailsList
						items={items}
						enableShimmer={isLoading}
						selectionMode={SelectionMode.none}
						columns={columns}
						className="DetailsListRoot"
						layoutMode={DetailsListLayoutMode.justified}
						onActiveItemChanged={itemClick} />
				}
			</div>
		</>
	);
}

export default SubscriptionList;