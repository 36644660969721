import { Breadcrumb, ChoiceGroup, getTheme, IBreadcrumbItem, IChoiceGroupOption, Icon, Image, ImageIcon, mergeStyleSets, Pivot, PivotItem, PrimaryButton, ProgressIndicator, Stack, Text } from '@fluentui/react';
import * as React from 'react'
import { useState } from 'react';
import { cents_to_dollars } from '../../../src/helpers';
import { navigateTo, useAppSelector } from '../../../src/hooks';
import { getLoggedIn } from '../../../src/reducers/auth';
import { doLogin } from '../../LoginButton';
import { Question } from '../../Question';
import { logo } from '../../woocommerce/Subscription';

declare var require: any;
const woocommerceEventTrigger = require('../../woocommerce/woo-event-trigger.jpg')

const theme = getTheme();

const style = mergeStyleSets({
	container: {
	},
	title: [
		theme.fonts.xxLarge,
	],
	logo: {
		paddingRight: '10px',
		marginBottom: '-15px'
	},
	subtitle: [
		theme.fonts.mediumPlus,
		{
			margin: '2rem 0',
		}
	],
	ctaSection: [
		{
			marginBottom: '2rem'
		}
	],
	controlHeader: [
		theme.fonts.mediumPlus,
		{
			fontWeight: 'bold',
			paddingBottom: '5px'
		}
	],
	billingFrequency: [
		theme.fonts.medium,
		{
			color: theme.palette.neutralSecondary,
			fontWeight: 'bold'
		}
	],
	subtotal: [
		theme.fonts.large,
		{
			color: theme.palette.neutralSecondary,
			fontWeight: 'bold'
		}
	],
	subtotalAfter: [
		theme.fonts.medium,
		{
			color: theme.palette.neutralSecondary,
			fontWeight: 'bold',
			fontStyle: 'italic'
		}
	],
	processedByWoo: [
		theme.fonts.small,
		{
			color: theme.palette.themePrimary,
			paddingTop: '5px',
			margin: 0
		}
	],
	textBlock: [
		theme.fonts.mediumPlus,
	],
	colored: {
		color: '#944'
	},
	highlight: {
		backgroundColor: 'rgba(255, 245, 100, 0.60)',
		padding: '0 4px',
	},
	pointyList: [
		{
			listStyle: "none",
		}
	],
	listItem: [
		theme.fonts.medium,
		{
			padding: '5px'
		}
	]

});


export const Woopower: React.FC = () => {
	const [pkg, setPackage] = useState<string>('month');
	const [loading, setLoading] = useState<boolean>(false);

	const prices = useAppSelector((state) => state.subscription.prices);
	const subUrl = useAppSelector((state) => state.subscription.subscriptionUrl);

	const loggedIn = useAppSelector(getLoggedIn);

	const breadcrumbs: IBreadcrumbItem[] = [
		{
			text: 'Products',
			key: 'products',
		},
		{
			text: 'Power Automate for WooCommerce',
			key: 'woopower'
		}
	];

	const price:() => string = () => {
		return cents_to_dollars(prices[pkg]);
	}

	const subscriptionLengthOptions: IChoiceGroupOption[] = [
		{
			key: 'month',
			text: `${cents_to_dollars(prices['month'])} per month`,
			styles: {
				root: style.billingFrequency
			}
		},
		{
			key: 'year',
			text: `${cents_to_dollars(prices['year'])} per year`,
			styles: {
				root: style.billingFrequency
			}
		},
	];

	const buttonText = (pkg) => {
		if (!loading) {
			return 'Buy at reenhanced.com';
		} else {
			return 'Loading...';
		}
	}

	const handleButtonClick = () => {
		window.location.href = "https://reenhanced.com/products/power-automate-for-woocommerce/";
		return;

		if (loggedIn) {
			doSubscription();
		} else {
			doLogin();
		}
	}

	const doSubscription = () => {
		let url: URL = new URL(subUrl);
		url.searchParams.append('billing_period', pkg);
		setLoading(true);

		window.location.href = url.href;
	}

	return (
		<>
		<Breadcrumb items={breadcrumbs} />
		<div className={style.container}>
			<h1 className={style.title}>
				<ImageIcon imageProps={{ src: logo, width: "50px" }} className={style.logo} />
				Power Automate for WooCommerce
			</h1>

			<div className={style.subtitle}>
				Connect your WooCommerce store to Power Automate with this amazing WordPress plugin.
			</div>

			<div className={style.ctaSection}>
				<Stack horizontal horizontalAlign="space-between">

					<div>
						<PrimaryButton
							text={buttonText(pkg)}
							onClick={handleButtonClick}
							disabled={loading}
						/>
						{ loggedIn && 
							<Text as='h5' block className={style.processedByWoo}>
								<Icon iconName='Info' style={{ paddingRight: '5px' }}/>
								Processed by WooCommerce.com
							</Text>
						}
					</div>

					<div></div>
					<div></div>
				</Stack>

			</div>
			{ loading && <ProgressIndicator /> }

			<Pivot>
				<PivotItem headerText="Details">
					<Text className={style.textBlock} block>
						<p>
							Automate your way to bliss using Power Automate licensing that may already be included with your organization's existing Microsoft licensing. Form the tightest possible integration between WooCommerce and Teams, Dynamics 365, Excel or any other Microsoft service using Microsoft's own automation solution.
						</p>

						<p>
							This WordPress plugin is the easiest way to integrate your WooCommerce store with all <span className={style.colored}>700+</span> services offered in Power Automate, including Microsoft Dynamics, Microsoft Teams, Outlook, Excel, and more. <span className={style.highlight}>Our users find creating triggers on Orders or Subscriptions to be most useful.</span>
						</p>

						<Image src={woocommerceEventTrigger} style={{ maxWidth: '40%', margin: '0 auto' }} alt='Showing an example of the power automate trigger' />

						<p>
							Through the Power Platform, this plugin allows WooCommerce to become an active part of your larger business processes, simplifying or even eliminating manual steps.
						</p>

						<p>
							Another super popular use case for handling order refunds is use of the Approval Process which helps alert the team when resulting actions need to happen.
						</p>

					</Text>

					<Text className={style.textBlock} block>
						<p>
							Plus, you can easily extend the source code to support even more!
						</p>
						<p>
							<span className={style.highlight}>Only through Power Automate can you get the deepest and best supported integration with your Microsoft products.</span> Whether you want to integrate with Teams or have deep integration with Microsoft Dynamics, this add-on will allow you to extend your WooCommerce store in ways that will make you wonder how you did without. 
						</p>
					</Text>

				</PivotItem>
				<PivotItem headerText="Use Cases">
					<Text block variant="xLarge" style={{paddingTop: '20px', fontWeight: 'bold'}}>
						Top 10 Use Cases for this Plugin:
					</Text>
					<ul className={style.pointyList}>
						{[
							'Look up subscription status for customers before any automation',
							'Automatically create products from your data in Dynamics 365',
							'Build an automated process after orders are placed or after order status changes',
							'Automatically create a list of completed orders inside SharePoint or Excel',
							'Build a Power App that interacts with WooCommerce',
							'Automatically create coupons',
							'Build an approval process into your order workflow.',
							'Integrate your WooCommerce store with Microsoft Dynamics 365, Business Central, NAV, or Dataverse',
							'Trigger flows on Customers, Coupons, Orders, Products, and Subscriptions',
							'Directly integrate WooCommerce and Excel'
						].map((item, i) => 
								<li key={i} className={style.listItem}>&#128073; {item}</li>
						)}
					</ul>

				</PivotItem>
				<PivotItem headerText="Frequently Asked Questions">
					<Question question="Is Enterprise licensing available?">
						<p>Yes. Please contact us directly for bulk purchases and enterprise accounts. Enterprise accounts have higher usage limits and can be configured for on-premises deployment.</p>
					</Question>

					<Question question="What plugins are supported?">
						<p>The initial launch supports core WooCommerce and WooCommerce Subscriptions. We are planning support for Memberships and Bookings in an upcoming release. </p>
					</Question>

					<Question question="What triggers and actions are available?">
						<p>All built-in triggers are supported, including subscription triggers.</p>
						<p>
							<strong>Triggers</strong> <br />
							<ul>
								<li>Coupon created</li>
								<li>Coupon updated</li>
								<li>Coupon deleted (moved to trash)</li>
								<li>Coupon restored</li>
								<li>Customer created</li>
								<li>Customer updated</li>
								<li>Customer deleted</li>
								<li>Order created</li>
								<li>Order updated</li>
								<li>Order deleted (moved to trash)</li>
								<li>Order restored</li>
								<li>Product created</li>
								<li>Product updated</li>
								<li>Product deleted (moved to trash)</li>
								<li>Product restored</li>
								<li>Subscription created</li>
								<li>Subscription updated</li>
								<li>Subscription deleted</li>
								<li>Subscription switched</li>
								<li>Subscription status changed</li>
								<li>Subscription renewed</li>
								<li>Subscription renewal failed</li>
								<li>Order status changed</li>
								<li>Order paid</li>
							</ul>
						</p>
						<p>For each of the resources below, the actions of Create, Update, Delete, Fetch one, Fetch a list (search) are supported:</p>
						<p>
							<strong>Resources</strong><br/>
							<ul>
								<li>Coupon</li>
								<li>Customer</li>
								<li>Order</li>
								<li>Order Note</li>
								<li>Product</li>
								<li>Subscription</li>
							</ul>
						</p>
						<p><em>Note: Some items may not appear if not enabled or available on your WooCommerce installation</em></p>
					</Question>

					<Question question="Can I modify or extend the plugin?">
						<p>Yes. We have constructed this plugin in a modular fashion and we're developing a starter-kit if you want to add more resources to your site.</p>
						<p>All you need to do to extend this is add a small bit of code as a child plugin and output a schema using JSON schema format. Contact us if you would like assistance building your extension, we're happy to help you.</p>
					</Question>

					<Question question="Am I entitled to updates?">
						<p>As long as you have an active subscription you are entitled to all updates which will be released regularly. Please use an active email address to signup so we can contact you with updates.</p>
						<p>If your subscription expires, your connection to Power Automate will not work.</p>
					</Question>

					<Question question="How many sites can I use this on?">
						<p>
							Each purchase of this software is for a single installation of WordPress, identified by Site URL. (Found in WordPress settings &gt; General)
						</p>
					</Question>

					<Question question="Can I use the same license in staging and production? Do I have to purchase multiple licenses?">
						<p>
							Only one site may be active at a time so that we know where to direct the Power Automate traffic. You can reset your license at any time by visiting the <a href="/subscriptions">Subscriptions</a> section of this website.
						</p>
					</Question>

					<Question question="Why is a WordPress.com login required?">
						<p>
							We have utilized the same authentication method as WooCommerce so that we may provide direct installation services in future updates.
						</p>
					</Question>

					<Question question="What are the requirements?">
						<p>
							You will need WordPress v4.7 or greater and WooCommerce v3.5.0 or greater. PHP 5.6.20 is required as well.
						</p>
					</Question>

					<Question question="Who is providing this?">
						<p>
							You are purchasing a plugin for WooCommerce that makes it compatible with Microsoft Power Automate. The plugin is authored and maintained by <a href="https://www.reenhanced.com/">Reenhanced</a>. Reenhanced is a separate company from Automattic, the owners of WooCommerce.
						</p>
						<p>
							If you require support, please email <a href="mailto:support@reenhanced.com">support@reenhanced.com</a> or fill out our <a href="https://reenhanced.com/contact">contact form</a>.
						</p>
					</Question>

					<Question question="Is my data private and secure?">
						<p>
							All data is protected by HTTP SSL security and is encrypted in transit. No data is stored in intermediary systems unless an error occurs, in which case request data is captured to help debugging.
						</p>
						<p>
							Trigger data is sent directly to Power Automate. Data for actions goes through a central routing server to ensure delivery to Power Automate.
						</p>
						<p>
							Audit tracking will be available locally on your WordPress server so that all requests can be reviewed. No audit records are stored in intermediary systems.
						</p>
						<p>
							Usage information is tracked on each license in the form of request counts.
						</p>
					</Question>

					<Question question="What are the usage limits?">
						<p>Your usage of this product is subject to a rate limit of 10 requests per second, which is handled automatically by Power Automate.</p>
						<p>Licenses are also subject to a total monthly action count of 5,000 actions. Please contact us if you anticipate a need for more actions.</p>
					</Question>


					
				</PivotItem>

			</Pivot>

		</div>
	</>);
}

export default Woopower;