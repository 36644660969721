import * as React from "react"
import { initializeIcons } from "@fluentui/react";
import { RootState } from "../../src/reducers";
import AppHeader from "../AppHeader";
import AppSidebar from "../AppSidebar";
import AppContent from "../AppContent";
import AppFooter from "../AppFooter";
import { useAppSelector } from "../../src/hooks";

import './styles.scss';

initializeIcons();

export const App: React.FunctionComponent<{}> = (props) => {
	const loggedIn = useAppSelector((state: RootState) => state.auth.loaded && state.auth.loggedIn)

	return (
		<div className="App">
			<AppHeader loggedIn={loggedIn} />
			<div className="body">
				<AppSidebar loggedIn={loggedIn} />
				<AppContent />
			</div>
		</div>
	);
}

export default App;