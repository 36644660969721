import Routes from "./Routes";
import * as React from 'react';
import { getTheme, mergeStyleSets, MessageBar, MessageBarType } from "@fluentui/react";
import Flash from "./Flash";
import AppCommandBar from "./AppCommandBar";

const theme = getTheme();

const styles = mergeStyleSets({
	AppContent: [
		"AppContent",
		{
			padding: '0 32px',
			width: '100%',
			overflow: 'auto',
			margin: '0',
			color: theme.palette.black,
		}],
	contentArea: [
		"contentArea",
	]
});

export const AppContent: React.FC = () => {
	return <div className={styles.AppContent}>
		<div className={styles.contentArea}>
			{Routes}
		</div>
	</div>;
}

export default AppContent;