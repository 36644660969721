import { ContextualMenu, getTheme, Icon, IContextualMenuItem, mergeStyleSets, Persona, PersonaSize, Stack } from "@fluentui/react"
import * as React from "react";
import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { NavLink, Link } from 'react-router-dom';
import { getUser } from "../src/reducers/auth";
import LoginButton from "./LoginButton";
import Rails from '@rails/ujs';

const theme = getTheme();

const styles = mergeStyleSets({
	AppHeader: [{
		display: 'flex',
		alignItems: 'center',
		padding: '0.5em 1em',
		justifyContent: 'space-between',
		background: 'linear-gradient(120deg, rgba(121,9,109,1) 0%, rgba(0,179,255,1) 100%)',
		color: 'white'
	}],
	logo: [{
		color: '#fff',
		fontSize: '24pt',
		fontFamily: '"Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, "Roboto", "Helvetica Neue", sans-serif',
		fontWeight: 'regular',
		textDecoration: 'none'

	}],
	logoText: {
		paddingLeft: '5px'
	},
	menuItem: [
		{
			margin: '0px 4px',
			display: 'inline-block',
			flexGrow: 1,
			textOverflow: 'ellipsis',
			overflow: 'hidden',
			whiteSpace: 'nowrap',
			textDecoration: 'none'
		}],
});

export const AppHeader: React.FunctionComponent<{loggedIn: boolean}> = (props) => {
	const personaRef = useRef(null);
	const user = useSelector(getUser);
	const [showMenu, setShowMenu] = useState(false);

	const items: IContextualMenuItem[] = [
		{
			key: 'logout',
			onRenderContent: () =>
				<a className={styles.menuItem} href="/logout" data-method="delete">Logout</a>,
			onClick: (ev) => Rails.handleMethod.call(ev.target, ev)
		}
	]

	return (
		<div className={styles.AppHeader}>
				<Link to="/" className={styles.logo}>
					<Icon iconName='WebAppBuilderFragmentCreate' />
					<span className={styles.logoText}>
						Build Better Software
					</span>
				</Link>

				<Stack.Item>
					{props.loggedIn &&
						<div ref={personaRef}>
							<Persona
								imageUrl={user.avatar_url}
								size={PersonaSize.size32}
								imageAlt={user.display_name}
								onClick={() => setShowMenu(!showMenu) } />

							<ContextualMenu
								target={personaRef}
								hidden={!showMenu}
								items={items}
								/>

						</div>
					}
				</Stack.Item>
		</div>
	)
}

export default AppHeader;