import { DefaultButton } from '@fluentui/react';
import * as React from 'react';
import * as ReactDOM from 'react-dom';

const LoginButton: React.FunctionComponent = () => {

	return <>
		<DefaultButton text="Login with WordPress.com" onClick={doLogin} />
	</>;

}

export const doLogin = () => {
	const authPath = "/users/auth/wordpress";
	const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");
	var formRef;
	var buttonRef;

	const form: JSX.Element = <form ref={ref => formRef = ref} action={authPath} method="POST">
			<input type="hidden" name="authenticity_token" value={csrf} />
			<input type="submit" ref={ref => buttonRef = ref} />
		</form>;

	const div = document.createElement('div');
	div.style.display = 'none';

	document.body.appendChild(div);

	ReactDOM.render(form, div);

	buttonRef.click();
}

export default LoginButton;